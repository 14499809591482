.map-container{
    background: #D9D9D9;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;

    width: 100%;
    height: 500px;
    .leaflet-container{
        height: 500px;
        width: 100%;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
    }
}
.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar{
    border: none;
}
.geosearch{
    position: absolute;
    left: 50px;

    .leaflet-bar-part{
        display: none;
    }
    .results{
        border-radius: 16px;
        background: #FFF;
        box-shadow: 0px -2px 12px 0px rgba(0, 0, 0, 0.05);
        margin-top: 4px;
        div{
            padding: 4px 10px;
            color: #30475E;
            font-size: 12px;
            line-height: 1.2;
            cursor: pointer;
            &:hover{
                color: var(--accentColor);
            }
        }
    }
}
.leaflet-control input[type="text"] {
    width: 300px;
    padding: 0 13px;
    border-radius: 21px;
    border: 1px solid #DDD;
    background: #FFF;
    outline: none;
    height: 42px;
}

.leaflet-control button {
    color: #7B7B7B;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 5px;
    font-size: 26px;
    height: 42px;
    background: transparent;
}