:root {
    --mainColor:#090909;
    --accentColor: var(--mainColor);
    --mainGradient: var(--mainColor);
    --bonusColor: #090909;
    --profileCard: #F9F9F9;
    --fontFamily: 'Rubik', sans-serif;
    --fontFamily2: 'Rubik';
    --profileItemColor: #7b7b7b2b;
    --notificationColor: #F9E9E6;
    --notificationFontColor: #F46B4D;
    --deliveryBonusColor: #426AF8;
    --presentBonusColor: #FF6B00;
}

//@import "./scss/nunito";
@import "./scss/comfortaa";
@import "./scss/iconMoon";
//@import "./scss/raleway";
@import "./scss/rubik";
@import "./scss/main";

@import "./scss/components/ui/droupdown";

@import "./scss/components/header";
@import "./scss/components/footer";
@import "./scss/components/mainSlider";
@import "./scss/components/productCard";
@import "./scss/components/modal";
@import "./scss/components/stories";
@import "./scss/components/cartProduct";
@import "./scss/components/cartModal";
@import "./scss/components/checkout";
@import "./scss/components/addressCard";
@import "./scss/components/ui/map";
@import "./scss/components/popularProductCard";
@import "./scss/components/ui/loader";
@import "./scss/components/loginModal";
@import "./scss/components/profile";
@import "./scss/components/categories-list";
@import "./scss/components/ui/topButton";
@import "./scss/components/samplePage";
@import "./scss/components/downloadAppPopup";
@import "./scss/components/stortiesComponent";
@import "./scss/components/skeletopLoading";
@import "./scss/components/ui/notificationTop";
@import "./scss/components/recommendedProducts";
@import "./scss/components/mobileFixMenu";
@import "./scss/components/cartDevices";
@import "./scss/components/cartDelivery";
@import "./scss/components/timeSelectModal";
@import "./scss/components/cartPaymentModal";
@import "./scss/components/deliveryStock";
@import "./scss/components/presentStock";
@import "./scss/components/cityModal";
@import "./scss/components/notifications";
@import "./scss/components/appAdvertisingBanner";


@import "./scss/responsive";
