*,
*::before,
*::after {
  box-sizing: border-box;
}

ul[class],
ol[class] {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.3;
}

ul[class],
ol[class] {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;

  &:focus {
    outline: none;
  }
}


img, video{
    width: 100%;
  //display: block;
  object-fit: cover;
}

img, video {
    max-width: 100%;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
}

html {
  font-size: 14px;
  scroll-behavior: smooth;
}

#root{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;
}
body {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 400;
  overflow-y: scroll;
  font-variant-numeric: lining-nums proportional-nums;
  color:var(--text-color);
  background: #F3F3F3;
}
h2{
    font-size: 24px;
    font-weight: 700;
    font-family: var(--fontFamily2);
}

h3{
    font-size: 20px;
    font-weight: 600;
}
h4{
    color:#222831;
    font-size: 18px;
    font-weight: 500;
    line-height: 133%;
}
h5{
    color:#222831;
    font-size: 16px;
    font-weight: 500;
    line-height: 133%;
}
h6{
    color:#222831;
    font-size: 18px;
    font-weight: 500;
    line-height: 133%;
}
.container{
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 48px;
    width: 100%;
    position: relative;
}
.main-btn{
    outline: none;
    border: none;
    height: 42px;
    padding: 0 20px;
    border-radius: 8px;
    background: var(--mainGradient);
    color: #FFF;
    font-weight: 400;
    line-height: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: .3s;
    justify-content: center;
    position: relative;
    white-space: nowrap;
    font-size: 16px;
    i{
        font-size: 18px;
        margin-right: 12px;
    }
    .count{
        margin-left: 10px;
    }

    &.left{
        i{
            margin: 0 0 0 10px;
        }
    }
    &.loading{
        pointer-events: none;

        &::after{
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: #ffffff73;
        }
        &::before{
            content: "";
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 2px dashed #F3F3F3;
            position: absolute;
            animation: rotate 2s linear infinite;
            z-index: 1;
            top: 50%;
            left: 50%;
        }
    }
    &:disabled{
        opacity: .7;
        font-size: 12px;
        cursor: no-drop;
    }
    &.wd-100{
        width: 100%;
    }
}
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.accent-btn{
    background: var(--accentColor);
}
.btn-icon{
    overflow-x: hidden;
    i{
        transition: .3s;
    }
    &:hover{
        i{
            transform: translateX(4px);
        }
        &::before{
            transform: translateX(0);
        }
    }
    &::before{
        position: absolute;
        content: "\e907";
        font-family: 'icomoon' !important;
        background: var(--mainGradient);
        width: 100%;
        height: 100%;
        border-radius: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 18px;
        transform: translateX(-100%);
        transition: .3s;
    }
    &:hover::before{
        display: none;
    }
}
.btn-cart{
    i{
        transition: .3s;
    }
    &:hover{
        i{
            transform: translateX(4px);
        }
    }
}
.btn-gray{
    border: 1px solid #DDD;
    background: none;
    color: #DDD;
}
.blink-button{
    position: relative;
    overflow-x: hidden;
    &:hover{
        .flare {
            position: absolute;
            top: 0;
            height: 100%;
            width: 45px;
            transform: skewX(-45deg);
            animation: flareAnimation;
            left: -100%;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.4));
            animation: flareAnimation .7s linear;
        }
    }
}
@keyframes flareAnimation {
    0% {
        left: -150%;
    }
    100% {
        left: 150%;
    }
}

.btn-sm{
    height: 32px;
}
.btn-outline{
    border: 1px solid var(--accentColor);
    background: #fff;
    color: var(--accentColor);

    /*&:hover{
        background: var(--accentColor);
        color: #fff;
    }*/
}
.simple-btn{
    background: none;
    color: var(--mainColor);
    padding: 0;
    height: fit-content;

    i{
        margin-right: 6px;
        font-size: 24px;
        transition: .3s;
    }
}
.input{
    border-radius: 8px;
    border: none;
    background: #F9F9F9;
    height: 42px;
    padding: 0 16px;
    outline: none;
}
.input-center{
    display: flex;
    align-items: center;
}
.textarea{
    border-radius: 21px;
    border: 1px solid #DDD;
    height: 80px;
    padding: 8px 20px;
    outline: none;
}
.mb-15{
    margin-bottom: 15px;
}
.mb-50{
    margin-bottom: 50px;
}
.mb-30{
    margin-bottom: 30px;
}
.input-wrapper{
    position: relative;
    display: flex;
    align-items: center;
    
    &:hover{
        span{
            color: var(--accentColor);
        }
    }
    input{
        width: 380px;
    }
    span{
        position: absolute;
        right: 20px;
        font-size: 18px;
        color: #9CA5AF;
        z-index: 6;
        cursor: pointer;
    }
}
.btn:first-child:active{
    background: none;
    border: none;
}
.error-page{
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 14px;

    p{
        max-width: 600px;
    }
    h5{
        font-size: 60px;
    }
}
.modal-dialog-centered{
    justify-content: center;
    margin: 0 auto;
}
.isLoading {
    pointer-events: none;
    opacity: .5;
    position: relative;
    min-height: 100px;
    width: 100%;
    height: 100%;
  
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -20px;
      margin-left: -20px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 3px solid var(--mainColor);
      border-left-color: transparent;
      animation: rotate .5s infinite linear;
      z-index: 99;
    }
  
    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
}
@media(max-width: 767px){
    .mb-30{
        margin-bottom: 20px;
    }
}
.slick-track{
    margin: 0;
}

.pagination{
    gap: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    li a{
        background: #fff;
        padding: 8px 10px;
        border-radius: 4px;
    }
    li.active{
        a{
            background: var(--mainColor);
            color: #fff;
        }
    }
}
.text-right{
    text-align: right;
    margin-bottom: 16px;
    color: var(--mainColor);
    cursor: pointer;
    font-size: 16px;
}
.text-accent{
    color: var(--mainColor);
    margin-top: 4px;
    cursor: pointer;
    font-size: 16px;
}
.description-flex{
    display: flex; 
    flex-wrap: wrap; 
    gap: 10px;
    margin-top: 24px;

    img{
        max-width: 180px;
        object-fit: contain;
    }
}

.qr-menu{
    header{
        display: none;
    }
    .qr-menu-header{
        padding: 20px 16px;
        display: flex;
        justify-content: space-between;
        img{
            max-width: 155px;
            max-height: 60px;
            width: auto;
            object-fit: contain;
        }
    }
    footer{
        display: none;
    }
    .qr-menu-cart{
        position: fixed;
        bottom: 16px;
        right: 20px;
    }
}
.search-values-container{
    position: relative;
    input{
        width: 100%;
    }
    .search-values-items{
        position: absolute;
        z-index: 4;
        background: #fff;
        width: 100%;
        border: 1px solid #F9F9F9;
        max-height: 178px;
        overflow-y: auto;
        top: 44px;
        border-radius: 8px;

        &::-webkit-scrollbar {
            width: 5px;
            opacity: 0;
            position: absolute;
        }
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            box-shadow: 0 0 2px rgba(0, 0, 0, .2) inset;
        }
        &::-webkit-scrollbar-thumb {
            background: #c1c1c1;
            border-radius: 4px;
            border: 1px solid #f1f1f1;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #000000;
        }
    }
    .search-values-item{
        padding: 8px 12px;
        cursor: pointer;

        &:hover{
            background: #F9F9F9;
        }
        &-sub{
            font-size: 12px;
        }
    }
}

#google_translate_element{
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 3;

    .goog-te-gadget-simple{
        white-space: nowrap;
        background: #ffffff;
        border: 2px solid #4f8df5;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .goog-te-gadget-icon{
        width: 26px;
        height: 26px;
    }
    .goog-te-gadget img{
        background-image: url('../images/Google_Translate_Icon.png') !important;
        background-size: contain;
    }
    .goog-te-gadget{
        span{
            display: none;
        }
    }
}
@media(max-width: 776px){
    #google_translate_element{
        bottom: 72px;
    }
    .translated-ltr .theme-option-widgets{
        bottom: 200px;
        right: 35px;
    }
    #google_translate_element .goog-te-gadget-simple{
        width: 50px;
        height: 50px;
    }
}


///checkmark

.order-box {
    padding: calc(26px + 14 * (100vw - 320px) / 1600) 0;
    display: block;
    text-align: center;
    .order-image {
        width: calc(170px + 80 * (100vw - 320px) / 1600);
        height: auto;
        margin: 0 auto calc(16px + (28 - 16) * ((100vw - 320px) / (1920 - 320)));

        .checkmark {
            position: relative;
            padding: 30px;
            animation: checkmark 5m cubic-bezier(0.420, 0.000, 0.275, 1.155) both;
            display: inline-block;
            transform: scale(0.8);

            &__check {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 10;
                transform: translate3d(-50%, -50%, 0);
                fill: white;
            }

            &__background {
                fill: var(--mainColor);
                animation: rotate2 35s linear both infinite;
            }
            &__background2 {
                fill: #ff4646;
                animation: rotate2 35s linear both infinite;
            }
        }

        .star {
            position: absolute;
            animation: grow 3s infinite;
            fill: var(--mainColor);
            opacity: 0;

            &:nth-child(1) {
                width: 12px;
                height: 12px;
                left: 12px;
                top: 16px;
            }

            &:nth-child(2) {
                width: 18px;
                height: 18px;
                left: 168px;
                top: 84px;
            }

            &:nth-child(3) {
                width: 10px;
                height: 10px;
                left: 32px;
                top: 162px;
            }

            &:nth-child(4) {
                height: 20px;
                width: 20px;
                left: 82px;
                top: -12px;
            }

            &:nth-child(5) {
                width: 14px;
                height: 14px;
                left: 125px;
                top: 162px;
            }

            &:nth-child(6) {
                width: 10px;
                height: 10px;
                left: 16px;
                top: 16px;
            }

            @for $i from 1 through 6 {
                &:nth-child(#{$i}) {
                    animation-delay: $i*(3.0s/2);
                }
            }
        }

        .star2{
            fill: #ff4646 !important;
        }


        i {
            font-size: 50px;
            color: #4ead4e;
        }

        h2 {
            margin-top: 10px;
            margin-bottom: 15px;
        }

        p {
            font-size: 18px;
            text-transform: capitalize;
        }

        &.order-fail {
            i {
                color: var(--mainColor);
            }
        }
    }

    .order-contain {
        h3 {
            font-size: calc(21px + (24 - 21) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 700;
            margin-bottom: 6px;
        }

        h5 {
            margin-bottom: 8px;
            line-height: 1.4;
        }
    }
}
.error-color{
    color: #ff4646;
}
@keyframes grow {
    0%,
    100% {
        transform: scale(0);
        opacity: 0;
    }

    50% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes rotate2 {
    100% {
      transform: rotate(360deg);
    }
  }