.cart-product{
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px -2px 12px 0px rgba(0, 0, 0, 0.05);
    position: relative;
    margin: 0 12px 16px;

    .close{
        color: #9CA5AF;
        font-size: 20px;
        right: 12px;
        top: 14px;

        &:hover{
            color: red;
        }
    }
    .product-bonus{
        font-size: 12px;
        padding: 0 8px;
        font-weight: 600;
    }
}
.elems-flex{
    position: absolute;
    bottom: 6px;
    left: 6px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.cart-product-wrapper{
    display: flex;
    position: relative;
    img{
        width: 107px;
        height: 107px;
        object-fit: cover;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    p{
        color: #7B7B7B;
        font-size: 12px;
    }
}
.product-cart-content{
    padding: 12px 12px 12px 16px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .cart-product-price{
        display: flex;
        justify-content: space-between;
        margin-top: auto;
        align-items: end;

        .price{
            color: var(--mainColor);
            font-size: 17px;
            font-weight: 600;
            display: flex;
            flex-direction: column;
        }
        span{
            color: #7B7B7B;
            font-size: 12px;
            text-decoration: line-through;
            font-weight: 400;
        }
    }
    .input-count{
        height: 32px;
    }

    h5{
        font-size: 14px;
        font-weight: 700;
        color: #232323;
        margin-right: 20px;
    }

}
.cart-product-bottom{
    border-radius: 12px;
    background: #F9F9F9;
    padding: 16px;
    color: #9CA5AF;
    margin: 4px;
    b{
        font-weight: 600;
        color: #222831;
    }
}
.cart-product-present{
    font-weight: 600;
    color: var(--presentBonusColor);
    display: flex;
    align-items: end;
    width: 100%;
    i{
        margin-right: 4px;
        font-size: 24px;
    }
}
.input-count{
    height: 42px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    background: #090909;

    .value{
        padding: 0 16px;
        color: #F3F3F3;
        font-weight: 600;
    }

    i{
        font-size: 20px;
        color: #F3F3F3;
    }
    .input-btn{
        cursor: pointer;
        transition: .3s;
    }
}
.input-count-mini{
    height: 32px;
    background: var(--mainGradient);
    color: #fff;

    .value{
        color: #fff;
        padding: 0 10px;
    }

}
.old-price{
    font-size: 14px;
    text-decoration: line-through;
    color: #DDD;
    font-weight: 400;
}