@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/IcoMoon/icomoon.eot?h5q9jj');
    src:  url('../fonts/IcoMoon/icomoon.eot?h5q9jj#iefix') format('embedded-opentype'),
      url('../fonts/IcoMoon/icomoon.ttf?h5q9jj') format('truetype'),
      url('../fonts/IcoMoon/icomoon.woff?h5q9jj') format('woff'),
      url('../fonts/IcoMoon/icomoon.svg?h5q9jj#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mini-user:before {
  content: "\e900";
}
.icon-mall:before {
  content: "\e901";
}
.icon-shop-bar:before {
  content: "\e902";
}
.icon-equalizer:before {
  content: "\e903";
}
.icon-filter-2:before {
  content: "\e905";
}
.icon-radio-on:before {
  content: "\e906";
}
.icon-chevron-right:before {
  content: "\e907";
}
.icon-cart:before {
  content: "\e908";
}
.icon-check-off:before {
  content: "\e909";
}
.icon-check-on:before {
  content: "\e90a";
}
.icon-chevron-down:before {
  content: "\e90b";
}
.icon-chevron-left:before {
  content: "\e90c";
}
.icon-chevron-up:before {
  content: "\e90d";
}
.icon-close:before {
  content: "\e90e";
}
.icon-facebook:before {
  content: "\e90f";
}
.icon-filter:before {
  content: "\e910";
}
.icon-gift:before {
  content: "\e911";
}
.icon-hit:before {
  content: "\e912";
}
.icon-instagram:before {
  content: "\e913";
}
.icon-message:before {
  content: "\e914";
}
.icon-new:before {
  content: "\e915";
}
.icon-radio-off:before {
  content: "\e916";
}
.icon-remove:before {
  content: "\e917";
}
.icon-whatsapp:before {
  content: "\e918";
}
.icon-youtube:before {
  content: "\e919";
}
.icon-globe:before {
  content: "\e91a";
}
.icon-search-1:before {
  content: "\e91b";
}
.icon-telegram:before {
  content: "\e91c";
}
.icon-twitter:before {
  content: "\e91d";
}
.icon-plus:before {
  content: "\e91e";
}
.icon-minus:before {
  content: "\e91f";
}
.icon-map-pin:before {
  content: "\e920";
}
.icon-pen:before {
  content: "\e921";
}
.icon-burger:before {
  content: "\e922";
}
.icon-user:before {
  content: "\e923";
}
.icon-location:before {
  content: "\e924";
}
.icon-delivery:before {
  content: "\e925";
}
.icon-devices:before {
  content: "\e926";
}
.icon-trash:before {
  content: "\e927";
}
.icon-present:before {
  content: "\e928";
}
.icon-chat:before {
  content: "\e929";
}
.icon-notification:before {
  content: "\e92a";
}
.icon-checked:before {
  content: "\e92b";
}
.icon-qr-code:before {
  content: "\e92c";
}
.icon-dropdown:before {
  content: "\e92d";
}
.icon-star:before {
  content: "\e92e";
}
.icon-chat-1:before {
  content: "\e92f";
}
.icon-user-2:before {
  content: "\e930";
}
.icon-symbol_info:before {
  content: "\e931";
}
.icon-bill:before {
  content: "\e932";
}
.icon-coin:before {
  content: "\e933";
}
.icon-faq:before {
  content: "\e934";
}
.icon-friends:before {
  content: "\e935";
}
.icon-pin:before {
  content: "\e936";
}
.icon-team:before {
  content: "\e937";
}
.icon-settings:before {
  content: "\e938";
}
.icon-user-thin:before {
  content: "\e939";
}
.icon-symbols_info:before {
  content: "\e93a";
}
.icon-exit:before {
  content: "\e93b";
}
.icon-download:before {
  content: "\e93e";
}
.icon-info:before {
  content: "\ea0c";
}
.icon-info1:before {
  content: "\ea0d";
}
.icon-heart:before {
  content: "\f19f";
}
.icon-like:before {
  content: "\f1a0";
}
.icon-content_copy:before {
  content: "\e93c";
}
.icon-Group:before {
  content: "\e93f";
}
.icon-password:before {
  content: "\e93d";
}
.icon-tiktok:before {
  content: "\e904";
}
