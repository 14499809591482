.app-banner{
    display: flex;
    padding: 45px 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../../images/banner_back4.png');

    .app-baner-content{
        display: flex;
        justify-content: space-between;
        h2{
            font-size: 24px;
            font-weight: 400;
            color: #F3F3F3;
            max-width: 331px;
        }
        .description-flex{
            margin-top: 0;
        }
        .subtitle{
            color: var(--mainColor);
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 2px;
            text-transform: uppercase;
            margin-bottom: 24px;
        }
    }
    .mob-image{
        width: 280px;
    }
}

@media(max-width: 996px){
    .app-banner{

        .app-baner-content{
            flex-direction: column;
            gap: 20px;
            .subtitle{
                font-size: 14px;
            }
            h2{
                font-size: 28px;
            }
        }

        .mob-image{
            width: 200px;
        }
    }
}

@media(max-width: 776px){
    .app-banner{
        .mob-image{
            grid-row: 2;
        }
        .app-baner-content{

            .subtitle{
                font-size: 12px;
            }
            h2{
                font-size: 24px;
            }
        }
    }
}