.cart-delivery{
    border-radius: 8px;
    background: #FFF;
    margin: 0 16px 16px;
}
.cart-delivery-item{
    border-bottom: 1px solid #F2F2F2;
    padding: 12px 16px;

    .cart-delivery-item-title{
        color: #232323;
        font-weight: 400;
        font-size: 16px;
    }

    .cart-delivery-item-content{
        color: var(--mainColor);
        font-weight: 600;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 8px;
        cursor: pointer;

        .flex-delivery{
            display: flex;
            flex-direction: column;

            &-mini{
                font-size: 12px;
                color: #23232399;
            }
        }

        i{
            font-size: 20px;
        }
    }

    .textarea{
        border: none;
        padding: 0;
        margin: 0;
        color: #7B7B7B;
        font-size: 14px;
        border-radius: 0;
        width: 100%;
        margin-top: 8px;
        height: auto;
    }
    .form-check-input{
        height: 22px;
        width: 44px;
        cursor: pointer;
    }
    .input{
        background: #F9F9F9;
        border: none;
        border-radius: 8px;
    }
}
.delivery-card-list{
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 230px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    margin-bottom: 10px;

    &::-webkit-scrollbar {
        width: 5px;
        opacity: 0;
        position: absolute;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        box-shadow: 0 0 2px rgba(0, 0, 0, .2) inset;
    }
    &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 4px;
        border: 1px solid #f1f1f1;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #000000;
    }

    &-item{
        padding: 15px 16px;
        border-radius: 8px;
        background: #F9F9F9;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        border: 1px solid #F9F9F9;

        &.active{
            border: 1px solid var(--mainColor);
        }

        i{
            position: relative;
            z-index: 2;
        }
    }

    .delivery-card-input{
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
        top: 0;
        left: 0;
    }


}

@media(max-width: 767px){
    .delivery-card-list{
        max-height: none;
    }
}