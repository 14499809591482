@font-face {
    font-family: "Comfortaa";
    src: url("../fonts/Comfortaa/Comfortaa-Regular.ttf");
    src: url("../fonts/Comfortaa/Comfortaa-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Comfortaa";
    src: url("../fonts/Comfortaa/Comfortaa-SemiBold.ttf");
    src: url("../fonts/Comfortaa/Comfortaa-SemiBold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}