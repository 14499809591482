.cart-offcanvas{
    background: #f3f3f7;

    &.offcanvas-end{
        width: 448px; 
    }
    .offcanvas-body{
        padding: 0;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 5px;
            opacity: 0;
            position: absolute;
        }
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            box-shadow: 0 0 2px rgba(0, 0, 0, .2) inset;
        }
        &::-webkit-scrollbar-thumb {
            background: #c1c1c1;
            border-radius: 4px;
            border: 1px solid #f1f1f1;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #000000;
        }
    }
    .offcanvas-title{
        color: #222831;
        font-size: 24px;
        font-weight: 600;
    }
    .product-checkbox{
        margin: 4px 16px 20px;
        font-size: 16px;
    }
    .present-stock, .delivery-stock{
        margin: 0 12px;
        border-radius: 16px;
        padding: 16px;
        background: #FFF;
        box-shadow: 0px -2px 52.7px 0px rgba(84, 68, 104, 0.12);
        margin-bottom: 16px;

        .delivery-stock-top{
            margin: 0;
        }
    }
}
.clear-cart{
    color: #7B7B7B;
    display: flex;
    justify-content: end;
    padding: 0 12px 12px;
    cursor: pointer;

    i{
        margin-right: 4px;
        color: #7B7B7B;
    }
}
.empty-cart{
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;


    p{
        color: #666666;
        font-size: 16px;
        margin-bottom: 24px;
    }
    img{
        //max-width: 200px;
        object-fit: contain;
        margin-bottom: 24px;
    }
    .main-btn{
        width: 100%;
    }

    .main-svg{
        fill: var(--mainColor);
        
    }
}
.cart-container{
    display: flex;
    flex-direction: column;
    height: 100%;

    .recommended-card{
        margin: 5px;
        height: 160px;
        max-width: 130px;
        img{
            width: 120px;
            height: 70px;
        }
        .recommended-card-title{
            font-size: 12px;
        }
    }
}
.cart-summary{
    margin-top: auto;
    background: #fff;
    padding: 24px 16px;
    .main-btn{
        width: 100%;
        margin-top: 16px;
    }
    h5{
        font-size: 20px;
        font-weight: 600;
        color: #283034;
        margin-bottom: 34px;
    }
    h3{
        font-size: 24px;
        color: #283034;
        font-weight: 600;
        margin-bottom: 16px;
    }
}
.cart-summary-wrapper{
    margin-bottom: 32px;
}
.bonus-switcher{
    border-bottom: 1px solid #E1E1E1;
    padding-bottom: 16px;
    .text{
        span{
            font-size: 12px;
        }
    }
    .form-switch .form-check-input{
        height: 22px;
        width: 44px;
    }
}

.list-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #283034;
    font-weight: 400;

    span{
        font-weight: 700;
        font-size: 16px;
    }

    .product-bonus{
        font-size: 14px;
        padding: 6px;
        border-radius: 6px;
    }

    &:not(:last-child){
        margin-bottom: 16px;
    }
}
.bonus-switcher{
    margin-bottom: 12px;
}
.discount{
    color: #FF6B00;
}
.pd-12{
    padding: 0 12px;
}
.input-flex{
    display: flex;
    align-items: center;
    margin-top: 12px;
    gap: 12px;
    .main-btn{
        margin: 0;
        width: auto;
    }
    .input{
        width: 100%;
    }
}