@font-face {
    font-family: "Rubik";
    src: url("../fonts/Rubik/Rubik-Light.ttf");
    src: url("../fonts/Rubik/Rubik-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Rubik";
    src: url("../fonts/Rubik/Rubik-LightItalic.ttf");
    src: url("../fonts/Rubik/Rubik-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: "Rubik";
    src: url("../fonts/Rubik/Rubik-Regular.ttf");
    src: url("../fonts/Rubik/Rubik-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Rubik";
    src: url("../fonts/Rubik/Rubik-Medium.ttf");
    src: url("../fonts/Rubik/Rubik-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Rubik";
    src: url("../fonts/Rubik/Rubik-MediumItalic.ttf");
    src: url("../fonts/Rubik/Rubik-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: "Rubik";
    src: url("../fonts/Rubik/Rubik-SemiBold.ttf");
    src: url("../fonts/Rubik/Rubik-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Rubik";
    src: url("../fonts/Rubik/Rubik-SemiBoldItalic.ttf");
    src: url("../fonts/Rubik/Rubik-SemiBoldItalic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: "Rubik";
    src: url("../fonts/Rubik/Rubik-Bold.ttf");
    src: url("../fonts/Rubik/Rubik-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Rubik";
    src: url("../fonts/Rubik/Rubik-BoldItalic.ttf");
    src: url("../fonts/Rubik/Rubik-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: "Rubik";
    src: url("../fonts/Rubik/Rubik-ExtraBold.ttf");
    src: url("../fonts/Rubik/Rubik-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: "Rubik";
    src: url("../fonts/Rubik/Rubik-ExtraBoldItalic.ttf");
    src: url("../fonts/Rubik/Rubik-ExtraBoldItalic.ttf") format("truetype");
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: "Rubik";
    src: url("../fonts/Rubik/Rubik-ExtraBoldItalic.ttf");
    src: url("../fonts/Rubik/Rubik-ExtraBoldItalic.ttf") format("truetype");
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: "Rubik";
    src: url("../fonts/Rubik/Rubik-Black.ttf");
    src: url("../fonts/Rubik/Rubik-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: "Rubik";
    src: url("../fonts/Rubik/Rubik-BlackItalic.ttf");
    src: url("../fonts/Rubik/Rubik-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
}