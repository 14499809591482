.delivery-stock{
    position: relative;
    cursor: pointer;

    &-title{
        height: 56px;
        width: 56px;
        border-radius: 50%;
        background: #FFF;
        //box-shadow: 0px 1px 8.8px 0px rgba(0, 0, 0, 0.10);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        i{
            font-size: 24px;
            color: var(--deliveryBonusColor);
        }
        .output{
            background: var(--deliveryBonusColor);
            height: 50px;
            width: 50px;
            border-radius: 50%;
            position: absolute;
            clip-path: polygon(50% 0%, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 50%, 50% 50%);
            z-index: 1;
            transition: .3s;
        }
        .output-inner{
            background: #F2F2F2;
            height: 50px;
            width: 50px;
            border-radius: 50%;
            position: absolute;
        }
        .inner{
            height: 46px;
            width: 46px;
            border-radius: 50%;
            background: #fff;
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: .3s;
        }
    }

    &.full{
        .delivery-stock-title i{
            color: #fff;
            font-size: 30px;
        }
        .delivery-stock-title .inner{
            background: var(--deliveryBonusColor);
            width: 56px;
            height: 56px;
            position: relative;

            &::after{
                position: absolute;
                content: "";
                width: 66px;
                height: 66px;
                background: #426AF859;
                border-radius: 50%;
                z-index: -1;
                opacity: 0;
            }

            &::before{
                position: absolute;
                content: "";
                width: 80px;
                height: 80px;
                background: #426AF859;
                border-radius: 50%;
                z-index: -1;
                opacity: 0;
                animation: presentAnimation .7s linear;
            }
        }
    }
}
.delivery-stock-modal{
    .modal-content{
        width: 375px;
    }
}

.delivery-stock-content{
    background: #fff;
    border-radius: 16px;
    padding: 40px 16px 32px;
    right: 0;
    position: relative;

    .main-btn{
        margin-top: 24px;
        width: 100%;
    }
    .icon-close{
        position: absolute;
        right: 16px;
        top: 12px;
        font-size: 24px;
        cursor: pointer;
    }
    &.full{
        .delivery-stock-title i{
            color: #fff;
            font-size: 30px;
        }
        .delivery-stock-title .inner{
            background: var(--deliveryBonusColor);
            width: 56px;
            height: 56px;
        }
    }
}

.delivery-stock-top{
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    gap: 16px;

    p{
        color: rgba(35, 35, 35, 0.60);
        font-size: 14px;
        font-weight: 500;
    }
    &-title{
        color: #232323;
        font-size: 16px;
        font-weight: 600;
    }
}

.delivery-stock-item{
    color: #232323;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 12px 0;

    &:not(:last-child){
        border-bottom: 1px solid #E5E5E5;
    }
}

.buttons-flex{
    display: flex;
    gap: 15px;
    align-items: center;
}

@keyframes presentAnimation{
    0%{
        opacity: 1;
        transform: scale(1.1);
    }
    100%{
        transform: scale(1.2);
        opacity: 0;
    }
}

@keyframes scaleAnimation{
    0%{
        transform: scale(1.2);
    }
    100%{
        transform: scale(1);
    }
}

@media(max-width: 996px){
    .buttons-flex{
        position: fixed;
        bottom: 20px;
        right: 84px;
        z-index: 1;
    }
}

@media(max-width: 767px){
    .buttons-flex{
        bottom: 76px;
        right: 20px;
    }
    .top-bar-wrapper{
        display: none;
    }

    .delivery-stock-modal{
        .modal-dialog{
            padding: 0;
            margin: 0;
            align-items: end;
            height: 100vh;
        }
        .modal-content{
            width: 100%;
        }
        .delivery-stock-content{
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}