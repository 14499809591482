.slider-container{
    margin-left: calc(((100vw - 1250px)/2) + 48px);
    overflow: hidden;
}
.recent-slider{
    /*.slick-slide{
        padding: 20px 0;
    }*/
    .slick-track{
        margin: 0;
        display: flex;
    }
}
.popular-card{
    display: flex;
    gap: 8px;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px -2px 12px 0px rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    height: 80px;
    cursor: pointer;
    transition: .3s;

    .overflow-hidden{
        overflow: hidden;
        position: relative;
    }

    &:hover{
        box-shadow: 0px -2px 12px 0px var(--mainColor);
    }

    .bonus-gained{
        position: absolute;
        top: 4px;
        left: 4px;
        background: var(--bonusColor);
        color: #fff;
        border-radius: 27px;
        font-size: 12px;
        padding: 2px 8px;
    }

    h4{
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        line-height: 1;
        font-size: 14px;
    }
    img{
        width: 95px;
        height: 80px;
        object-fit: cover;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
        transition: .3s;
    }
}
.popular-card-content{
    padding: 16px 16px 16px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    h4{
        &:not(:last-child){
            //margin-bottom: 8px;
        }
    }
    h4{
        &:last-child{
            margin-top: auto;
        }
    }
    .price{
        font-size: 16px;
    }
    .old-price{
        margin-top: auto;
    }
}
.active-text{
    color: var(--mainColor);
}
.popular-card{
    .popular-card-content{
        h5{
            font-weight: 600;
        }
        .price{
            margin: 0;
        }
    }
}
.address-grid{
    .popular-card{
        height: auto;
    }
}
.overflow-con-hidden{
    overflow: hidden;
}