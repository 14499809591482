.download-popup-container{
    display: flex;
    position: fixed;
    bottom: 10px;
    justify-content: center;
    z-index: 12;
    left: calc((100vw - 690px)/2);
}
.download-popup{
    background: #444243;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    width: 690px;
    padding: 12px 140px 12px 12px;
    border-radius: 14px;
    position: relative;
    cursor: pointer;
    background-image: url('../../images/min_banner2.png');

    .download-close{
        color: #fff;
        position: absolute;
        right: 10px;
        cursor: pointer;
        top: 10px;
        font-size: 20px;
        z-index: 10;
        transition: .3s;

        &:hover{
            transform: scale(1.1);
        }
    }
}
.download-popup-title{
    color: #fff;
    font-size: 20px;
    font-weight: 600;
}
.download-popup-description{
    color: #BABABA;
    font-size: 18px;
    margin-top: 6px;
    max-width: 400px;
}
.popup-modal{
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 37px 42px;
    border-radius: 14px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-image: url('../../images/modal_banner3.png');

    h3{
      font-size: 24px;
      margin-bottom: 20px;
    }
    .subtitle{
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 10px;
    }
    .main-btn{
        margin-top: 20px;
    }
    img{
        width: auto;
        object-fit: contain;
    }
    .description-flex{
        img{
            max-width: 100px;
        }
    }
}
.popup-modal-content{
    .modal-content{
        border: none;
    }
    .modal-dialog{
        max-width: 696px;   
    }
    .modal-body{
        padding: 0;
    }
}
.btn-modal{
    outline: none;
    border: none;
    background: #424242;
    color: #fff;
    height: 36px;
    padding: 0 16px;
}


@media(max-width: 996px){
    .download-popup-container{
        left: calc((100vw - 560px)/2);
    }
    .download-popup{
        width: auto;
    }
    .popup-modal{
        grid-template-columns: 1fr;
    }
}

@media(max-width: 600px){
    .download-popup-container{
        left: auto;
    }
}
@media(max-width: 767px){
    .download-popup-container{
        position: relative;
        top: 0;
        bottom: auto;
        left: 0;

        .download-popup{
            border-radius: 0;
            padding: 10px;
            width: 100%;
        }
        .download-popup-title {
            font-size: 16px;
        }
        .download-popup-description{
            max-width: 100%;
            font-size: 14px;
        }
    }
    .description-flex{
        img{
            max-width: 100px;
        }
    }
    .popup-modal-content{
        .popup-modal{
            padding: 24px 16px;
            h3{
                font-size: 20px;
                margin-bottom: 10px;
            }
            .subtitle{
                font-size: 14px;
            }
        }
    }
}
@media(max-width: 380px){
    .download-popup-container{
        .download-popup{
            padding: 6px;
        }
        .download-popup-title {
            font-size: 14px;
            margin-right: 10px;
        }
        .download-popup-description{
            font-size: 12px;
            margin-top: 0;
        }
    }
}