.notification-card{
    padding: 16px 16px 4px;
    border-radius: 16px;
    background: #FFF;
    cursor: pointer;

    &:not(:last-child){
        margin-bottom: 16px;
    }
    &-title{
        color: #232323;
        font-size: 14px;
        display: inline-flex;
        align-items: center;
        width: 100%;
        .read{
            margin-left: auto;
            height: 8px;
            width: 8px;
            border-radius: 50%;
            background: linear-gradient(0deg, var(--accent, #33CD55) 0%, var(--accent, #33CD55) 100%), linear-gradient(180deg, #DBFF00 3.5%, #3ACF15 62.5%);

        }
    }

    &-date{
        color: #7B7B7B;
        text-align: right;
        font-size: 12px;
        font-weight: 500;
    }
}