.skeleton-loading{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
    margin-bottom: 50px;

    .skeleton-item{
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 10px;
        border-radius: 8px;
    }
    .skeleton-img{
        height: 225px;
        width: 100%;
        background:#DDDDDD;
        margin-bottom: 8px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
    .skeleton-title{
        background: #DDDDDD;
        width: 150px;
        height: 23px;
        margin-bottom: 6px;
        border-radius: 4px;
    }
    .skeleton-text{
        background: #DDDDDD;
        width: 90%;
        height: 18px;
        border-radius: 4px;

        &:not(:last-child){
            margin-bottom: 6px;
        }
    }
    .skeleton-button{
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 14px;
    }
    .skeleton-price{
        background: #DDDDDD;
        height: 32px;
        border-radius: 4px;
        width: 70px;
    }
    .skeleton-right{
        background: #DDDDDD;
        height: 32px;
        width: 115px;
        border-radius: 4px;
    }
}
.product-loading{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
    position: relative;
}
.loading-top{
    margin-bottom: 35px;
    background: #DDDDDD;
    border-radius: 4px;
    height: 28px;
    width: 348px;
}
.loading-top2{
    margin-bottom: 35px;
    background: #DDDDDD;
    border-radius: 4px;
    height: 28px;
    width: 188px;
}
.product-loading-item{
    background: #fff;
    //padding: 10px;
    border-radius: 8px;
    min-width: fit-content;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    //margin: 5px;
    height: 252px;

    .product-loading-img{
        background: #DDDDDD;
        width: 100%;
        height: 140px;
        margin-bottom: 8px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
    .product-loading-title{
        background: #DDDDDD;
        height: 26px;
        width: 90%;
        border-radius: 4px;
    }
    .product-loading-price{
        background: #DDDDDD;
        height: 26px;
        width: 60%;
        border-radius: 4px;
        margin-top: auto;
        margin-bottom: 10px;
    }
}
.stories-loading{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 32px;
    position: relative;
    overflow-x: hidden;
}
.stories-loading-item{
    height: 246px;
    width: 100%;
    background: #fff;
    border-radius: 24px;
    border: 3px solid #DDD;
    padding: 6px;
}
.stories-loading-img{
    border-radius: 16px;
    background: #DDDDDD;
    width: 100%;
    height: 100%;
}

.flare{
    position: absolute;
    top: 0;
    height: 100%;
    width: 90px;
    transform: skewX(-45deg);
    animation: flareAnimation;
    left: -150%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.4));
    animation: flareAnimation 1s infinite linear;
}
.header-loading{
    position: relative;
    overflow: hidden;
    background: #fff;
    .loading-logo{
        border-radius: 4px;
        width: 70px;
        height: 28px;
        background: #DDDDDD;
    }
    .header-loading-top{
        border-bottom: 1px solid #DDD;
    }
    .header-loading-top-wrapper{
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .loading-list{
        display: flex;
        gap: 24px;

        div{
            background: #F7F7F7;
            height: 28px;
            border-radius: 4px;

            &:nth-child(1){
                width: 100px;
            }
            &:nth-child(2){
                width: 40px;
            }
            &:nth-child(3), &:nth-child(4){
                width: 65px;
            }
        }
    }
    .loading-list-big{
        display: flex;
        gap: 24px;

        div{
            background: #F7F7F7;
            height: 43px;
            border-radius: 4px;

            &:nth-child(1){
                width: 300px;

            }
            &:nth-child(2){
                width: 84px;
                background: #DDDDDD;
            }
            &:nth-child(3){
                width: 132px;
                background: #DDDDDD;
            }
        }
    }

    .loading-logo-center{
        width: 123px;
        height: 47px;
        background: #DDDDDD;
        border-radius: 4px;
    }
    .header-loading-center{
        height: 88px;
    }
    .header-loading-bottom{
        height: 82px;
    }
}
.loading-bottom-left{
    display: flex;
    gap: 28px;
    div{
        background: #DDDDDD;
        border-radius: 4px;
        width: 45px;
        height: 28px;
    }
}
.loading-button{
    border-radius: 4px;
    background: #DDDDDD;
    width: 132px;
    height: 43px;
}

.footer-loading{
    background: #fff;
    overflow: hidden;

    .footer-loading-wrapper{
        border-bottom: 1px solid #E2E5E6;
        padding: 50px 0;
        display: flex;
        justify-content: space-between;
    }
    .loading-1{
        background: #DDDDDD;
        height: 23px;
        width: 70px;
        border-radius: 4px;
    }
    .loading-md{
        width: 100px;
        margin-bottom: 15px;
        height: 18px;
    }
    .loading-bg{
        width: 131px;
        margin-bottom: 15px;
        height: 18px;
    }
    .loading-title{
        margin-bottom: 20px;
        width: 83px;
    }
    .loading-title-big{
        margin-bottom: 20px;
        width: 130px;
    }
    .loading-title-1{
        margin-bottom: 20px;
        width: 70px;
    }
    .loading-mn{
        margin-bottom: 15px;
        height: 18px;
    }
    .loading-bg-last{
        height: 18px;
        width: 89px;
    }
}
.loading-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px 30px;

    .loading-1{
        height: 18px;
    }

    .loading-1:nth-child(2){
        width: 69px;
    }

    .loading-1:nth-child(3){
        width: 100px;
    }

    .loading-1:nth-child(4){
        width: 60px;
    }
    .loading-1:nth-child(6){
        width: 89px;
    }
    .loading-1:nth-child(9){
        width: 60px;
    }
}
.footer-loading-bottom{
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: start;

    .loading-big{
        width: 240px;
        margin-bottom: 5px;
    }
    .loading-long{
        width: 370px;
        margin-bottom: 12px;
    }
    .loading-last{
        width: 113px;
    }
    .loading-flex{
        display: flex;
        gap: 7px;
        margin-bottom: 29px;
    }
}
.loading-mini{
    display: flex;
    gap: 20px;
    margin-top: 15px;

    div{
        background: #DDDDDD;
        border-radius: 4px;
        width: 25px;
        height: 25px;
    }
}
.loading-orders{
    position: relative;
    overflow: hidden;
    &-item{
        border-radius: 4px;
        background: #FFF;
        padding: 16px;

        &:not(:last-child){
            margin-bottom: 14px;
        }
        .block{
            border-radius: 3px;
            background: #DDD;
            height: 16px;
        }

        .loading-orders-top{
            display: flex;
            justify-content: space-between;
        }
        .block-1{
            width: 78px;
        }
        .block-2{
            width: 32px;
            height: 32px;
            border-radius: 8px;
        }
        .loading-orders-title{
            width: 230px;
            height: 32px;
            margin-bottom: 24px;
        }
        .block-3{
            width: 80px;
            height: 22px;
        }
        .block-4{
            span{
                width: 30px;
                height: 30px;
                border-radius: 4px;
                background: #DDD;
                display: inline-block;
                margin-left: 4px;
            }
        }
    }
}
.loading-address{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .loading-address-item{
        background-color: #fff;
        border-radius: 16px;
        padding: 16px;
        width: calc(50% - 20px);

        .block-1{
            height: 16px;
            border-radius: 4px;
            background: #DDD;
            width: 100%;
        }
        .block-2{
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-top: 8px;
            gap: 8px;

            .block-1{
                &:nth-child(1){
                    width: 70%;
                }
                &:nth-child(2){
                    width: 50%;
                }
                &:nth-child(4){
                    width: 80%;
                }
            }
        }
        .block-3{
            height: 16px;
            border-radius: 4px;
            background: #DDD;
            width: 40px;
            margin-top: 8px;
            margin-left: auto;
        }
    }
}
.loading-notification-item{
    width: 100%;
    padding: 16px;
    background: #fff;
    border-radius: 4px;

    .block-1{
        width: 100%;
        background: #DDD;
        border-radius: 4px;
        height: 16px;
    }
}
.order-detail-loading{
    background: #fff;
    padding: 16px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-product{
        background: #fff;
        border-radius: 16px;
        display: flex;
        gap: 10px;
        &:not(:last-child){
            margin-bottom: 20px;
        }
        .wd-100{
            width: 100%;
            padding: 10px 10px 10px 0;
            display: flex;
            flex-direction: column;
        }
        .block-img{
            width: 107px;
            height: 107px;
            background: #DDD;
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
        }
        .block{
            height: 16px;
            background: #DDD;
            width: 50%;
            border-radius: 4px;

            
            &:last-child{
                margin-top: auto;
                width: 100px;
            }
        }
    }

    &:not(:last-child){
        margin-bottom: 20px;
    }

    .block{
        height: 16px;
        background: #DDD;
        border-radius: 4px;
    }

    .block-1{
        width: 40%;
    }
    .block-2{
        width: 50%;
    }
    .block-3{
        width: 30%;
    }
}
.block-title{
    height: 27px;
    background: #ddd;
    width: 70%;
    margin-bottom: 20px;
    border-radius: 4px;
}
@keyframes flareAnimation {
    0% {
        left: -100%;
    }
    100% {
        left: 100%;
    }
}
@media(max-width: 1250px){
    .product-loading{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

        &-item{
            &:nth-child(6){
                display: none;
            }
        }
    }
    .skeleton-loading{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .stories-loading{
        gap: 16px;
    }
    .stories-loading-item{
        height: 212px;
    }
}

@media(max-width: 996px){
    .product-loading{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    }
}

@media(max-width: 767px){
    .skeleton-loading{
        grid-template-columns: 1fr 1fr;
    }
    .loading-list-big{
        div:nth-child(3){
            display: none;
        }
        div:nth-child(1){
            display: none;
        }
    }
    .stories-loading{
        .stories-loading-item{
            height: 140px;
        }
    }
    .header-loading .header-loading-top:nth-child(1){
        display: none;
    }
    .loading-bottom-left{
        display: none;
    }
    .loading-button{
        width: 100%;
    }
    .product-loading{
        grid-template-columns: 1fr 1fr 1fr 1fr;
        
        &-item:nth-child(5){
            display: none
        }
    }
    .footer-loading-wrapper, .footer-loading-bottom{
        flex-wrap: wrap;
        gap: 20px;
    }
    .loading-orders-item{
        background: #F9F9F9;
    }
    .loading-address{
        .loading-address-item{
            background: #F9F9F9;
            width: 100%;
            .block-2{
                display: none;
            }
            .block-3{
                display: none;
            }
        }
    }
    .stories-loading{
        grid-template-columns: 1fr 1fr 1fr 1fr;

        &-item:nth-child(1){
            display: none;
        }
    }
}
@media(max-width: 560px){
    .skeleton-loading{
        grid-template-columns: 1fr;
    }
    .product-loading{
        grid-template-columns: 1fr 1fr 1fr;
        &-item:nth-child(1){
            display: none;
        }
    }
    .footer-loading-bottom .loading-long{
        width: 90%;
    }
    .loading-top{
        width: 80%;
    }
}
@media(max-width: 480px){
    .skeleton-loading{
        grid-template-columns: 1fr;
    }
    .stories-loading{
        grid-template-columns: 1fr 1fr 1fr;

        &-item:nth-child(2){
            display: none;
        }
    }
    .product-loading{
        grid-template-columns: 1fr 1fr;

        &-item:nth-child(2){
            display: none;
        }
    }
}