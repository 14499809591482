.notification-top{
    background: var(--notificationColor);
    color: var(--notificationFontColor);
    padding: 5px;
    .notification-content{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
    }
}

@media(max-width: 380px){
    .notification-top {
        padding: 5px 0;
        font-size: 12px;

        .container{
            padding: 0 8px;
        }
    }
}