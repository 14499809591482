.lang-droupdown{
    padding: 16px 0;
    .btn{
        outline: none;
        border: none;
        background:none;
        color: #30475E;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        &:hover{
            color: var(--mainColor);
            i{
                transform: translateY(-4px);
            }
        }
        i{
            font-size: 24px;
            position: relative;
            transition: transform .3s;
            &::after{
                content: attr(data-title);
                position: absolute;
                top: -7px;
                background: var(--accentColor);
                color: #fff;
                padding: 0 4px;
                border-radius: 32px;
                font-size: 12px;
                border: 1px solid #FFF;
                right: -4px;
                line-height: initial;
                display: flex;
                align-items: center;
            }
        }
    }
}
.dropdown-menu{
    display: none;
    background: #fff;
    border-radius: 12px;
    box-shadow: rgba(6, 5, 50, 0.1) 0px 6px 20px;
    z-index: 10;

    a{
        display: block;
        padding: 8px 24px;
        cursor: pointer;
        transition: .3s;

        &:hover{
            background: rgb(243, 243, 247);
        }
    }
}
.dropdown-menu.show{
    display: block;
}

.product-droupdown{
    .dropdown-toggle{
        background: none;
        border: none;
        outline: none;
        color: #30475E;
        font-size: 20px;
        padding: 0;
        margin: 0;
        line-height: 0;
        
        &::after{
            display: none;
        }
    }
    .dropdown-menu{
        background: #30475E;
        color: #fff;
        padding: 16px;
        border-radius: 16px;
        width: 240px;

        .feature{
            display: flex;
            width: 100%;
            justify-content: space-between;
            &:not(:last-child){
                margin-bottom: 6px;
            }
            span{
                color: #fff;
                margin-left: auto;
                margin-left: 20px;
                white-space: nowrap;
            }
        }
    }
}

.search-product{
    display: flex;
    border-bottom: 1px solid #DDD;
    padding-bottom: 16px;
    cursor: pointer;

    &:not(:first-child){
        margin-top: 12px;
    }

    &:hover{
        background: #F7F7F7;
    }
}
.search-product-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
}

.droupdown-search{
    position: relative;

    .droupdown-search-content{
        position: absolute;
        border-radius: 16px;
        background: #FFF;
        box-shadow: 0px -2px 12px 0px rgba(0, 0, 0, 0.05);
        padding: 16px;
        width: 100%;
        z-index: 4;
        font-size: 16px;
        margin-top: 4px;
        font-weight: 600;
        max-height: 295px;
        overflow-y: auto;
        opacity: 0;
        visibility: hidden;

        &.visible{
            opacity: 1;
            visibility: visible;
        }

        img{
            width: 60px;
            height: 60px;
            margin-right: 8px;
        }

        &::-webkit-scrollbar {
            width: 6px;
            opacity: 0;
            position: absolute;
        }
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            box-shadow: 0 0 2px rgba(0, 0, 0, .2) inset;
        }
        &::-webkit-scrollbar-thumb {
            background: #c1c1c1;
            border-radius: 4px;
            border: 1px solid #f1f1f1;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #000000;
        }
    }
}

@media(max-width: 996px){
    .droupdown-search{
        width: 100%;
        .input-wrapper{
            width: 100%;

            input{
                width: 100%;
            }
        }
    }
}