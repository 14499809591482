.address-card{
    background: #fff;
    padding: 12px 16px;
    width: 100%;
    border-radius: 8px;
    position: relative;
    .card-item{
        display: flex;
        align-items: center;
    }
    .form-check-input{
        margin: 0 8px 0 0;
    }
    i{
        font-size: 20px;
        color: #30475E;
        cursor: pointer;
        margin-left: auto;
    }
    .card-grid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;

        span{
            margin-bottom: 8px;
        }
    }
    .card-item{
        margin-bottom: 8px;
        span{
            color: #7B7B7B;
            font-size: 16px;

            b{
                color: #232323;
                font-weight: 400;
            }
        }
    }
}
.address-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 100%;
}