footer{
    margin-top: auto;
}
.bottom-bar-wrapper{
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
    border-top: 1px solid #E2E5E6;

}
.white-logo{
    width: 115px;
    object-fit: contain;
}
.socials-nav{
    a{
        color: #30475E;
        font-size: 24px;
        &:not(:last-child){
            margin-right: 20px;
        }
    }
}
.footer-menu-wrapper{
    padding: 50px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
}
.footer-link{
    margin-top: 16px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    img{
        width: 104px;
        height: 38px;
    }
}
.footer-app{
    margin-bottom: 50px;

    h3{
        margin-bottom: 8px;
        font-family: var(--fontFamily2);
    }
    p{
        color: #5D6167;
    }
}
.menu-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 24px;
}
.footer-nav{
    h3{
        margin-bottom: 18px;
    }
    a, span, .nav-item{
        display: block;
        color: #474747;
        font-weight: 400;
        cursor: pointer;
        font-size: 16px;

        &:not(:last-child){
            margin-bottom: 15px;
        }
    }
}
.contacts{
    a, span{
        font-weight: 400;  
    }

    &-text{
        color: #474747;
        font-weight: 400;  
        font-size: 16px;
    }
}

@media(max-width: 767px){
    footer{
        margin-bottom: 72px;
    }
}