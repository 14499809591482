.recommended-wrapper{
    margin: 20px 12px 16px;

    h5{
        margin-bottom: 10px;
        color: #232323;
        font-size: 20px;
        font-weight: 600;
    }
}
.recommended-card{
    background: #fff;
    border-radius: 8px;
    min-width: fit-content;
    margin: 5px;
    cursor: pointer;
    height: 252px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    &-title{
        color: #232323;
        font-size: 12px;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        margin-bottom: 6px;
    }

    &-price{
        color: var(--mainColor);
        font-size: 16px;
        margin-top: auto;

        i{
            font-size: 16px;
        }
    }
    &-old-price{
        font-size: 12px;
        color: #9CA5AF;
        margin-top: auto;
        text-decoration: line-through;
    }

    .main-btn, .input-count{
        margin:5px 5px 10px;
    }
    img{
        width: 100%;
        height: 140px;
        margin-bottom: 8px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
}
.recommended-slider{
    width: 440px;
    .slick-slide{
        max-width: 130px;

        .recommended-card{
            .main-btn{
                font-size: 12px;
            }
        }
    }
}

.new-recommended-slider{
    margin-bottom: 20px;
    h5{
        color: #232323;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
    }
}
.product-modal-wrapper{
.product-recomended{
    border-radius: 16px;
    background: #ffffff;
    box-shadow: 0px 3px 10.2px 0px rgba(84, 68, 104, 0.12);
    display: flex !important;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    margin: 5px;
    max-width: 132px;
    height: 160px;
    cursor: pointer;

    img{
        width: 78px;
        height: 78px;
        object-fit: contain;
        border-radius: 11px;
        min-height: auto !important;
    }

    .title{
        color: #232323;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        margin-top: 8px;
    }

    .price{
        color: var(--mainColor);
        text-align: center;
        font-size: 16px;
        white-space: nowrap;
        font-weight: 600;
        margin-top: 12px;
        margin-top: auto;
        display: flex;
        align-items: center;
    }
}
}
.recomended-count{
    display: flex;
    align-items: center;
    font-size: 18px;
    gap: 8px;
    color: var(--mainColor);
    margin-top: auto;
    .value{
        font-size: 13px;

        span{
            color: #9CA5AF;
        }
    }
}
@media(max-width: 767px){
    .recommended-card{
        img{
            height: 116px;
        }
        .recommended-card-title{
            
        }
        .recommended-card-price{
          
        }
    }
    .new-recommended-slider{
        margin-bottom: 50px;
    }
}

@media(max-width: 500px){
    .recent-slider{
        width: 500px;
    }
    .recommended-card{
        height: 225px;
    }
}

@media(max-width: 400px){
    .recent-slider{
        width: 440px;
    }
}